import React from "react";
import {Helmet} from "react-helmet";

export default function CreativityConfidenceDepression() {
    return (
        <div>
            <Helmet>
                <title>创造、信心和抑郁 Creativity, Confidence, And Depression</title>
            </Helmet>
            <h6 className="App-essay-title">创造、信心和抑郁</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二三 七月</b>
                <br/><br/>
                <b>反过来问</b>
                <br/><br/>
                题中的三个元素，前两个是所有社会魂牵梦萦想获得的，最后一个是竭尽全力想摆脱的。
                <br/><br/>
                问题就出在这里。
                <br/><br/>
                所有社会的家长作风和官僚系统，它们的欲望都如此简洁明了，手段也如此干脆利落——因为想要，所以就问：如何才能最高效地获得？这种直截了当的逻辑在某些情况下非常高效，在某些情况下可能失灵，而在标题中的这三个场景里错得一塌糊涂。
                <br/><br/>
                所有人都不难发现，对创新和信心的渴望，就像对抑郁的避讳，社会越向往它，就会越压迫式地寻求它，直到精灵们的魔杖和快乐源泉都消失得无影无踪。
                <br/><br/>
                为什么会这样，举个例子：你应该如何成功？成功的方式和创造的方式几乎一样，它们都拥有无穷多种可能。所以想要给一个无穷复杂的多面体归纳一个标准答案，结局一定是以失败告终，只有数值解，没有解析解。所以查理·芒格说：不要问如何成功，而要反复询问如何才能失败？然后反过来做。
                <br/><br/>
                标题的问题正是这一类，属于不能正着问的问题：社会和企业如何鼓励创造？如何培育信心？如何消灭抑郁？对它们越是压迫性地寻找答案，答案就溜走的越快。因为它们潜在拥有的无穷个答案，终将耗尽所有苦苦搜寻的努力。
                <br/><br/>
                创意、信心、快乐，它们有无数个产生的原因，但失败和不开心的原因经常是有限的。问题自然变成了：怎样才能破坏创造？怎样才能打击信心？怎样才能加速抑郁？
                <br/><br/>
                这些问题就不回答了，因为我们似乎就生活在这口量身定制能回答上述三个问题的大缸里。假设我们找到了这些问题的答案，那只需要反过来做就可以了，而这也是我们自己唯一能采取的有效手段。
                <br/><br/>
                <b>被教育者</b>
                <br/><br/>
                孔子说：“不愤不启，不悱不发。举一隅不以三隅反，则不复也。”他对可塑之才有非常明晰的定义：如果那个人不是自己想奋发图强，就不要着急启蒙ta；如果那个人没有很想表达却不能很好地表达，那就别急着启发ta；如果那个人不能举一反三，那就再也别教ta了。
                <br/><br/>
                孔子在这句话里暗示了学习和教育的边界。培育人的首要因素是这个人自己的状态，然后教育才有意义。教育不是外界施加的，教育首先需要那个受教育者自己准备好，然后环境才能起些仅仅是引导辅助的作用，万事最终都取决于那个受教育者本身的状态和主观意愿。
                <br/><br/>
                创造、信心、幸福与否……这些事情只有在承认这个人的主观意愿处于绝对主导的情况下，外界的培育和呵护才有效。识别人的状态，给予必要性的支持而不是主导性的干预，就是最好的环境。这些美妙的东西，全是自己长出来的，无论你多想，都无法从地里把它们拔出来。
                <br/><br/>
                换句话说，创造力根本不需要环境培养，自信心也根本不需要外界鼓励，抑郁症也不需要极力劝导病人快速痊愈。尽量少破坏宽松的环境，减少对充满信心个体的打击，少劝导抑郁患者尽快康复——削弱这些反面因素，就已经能起到绝对实质性的作用了。
                <br/><br/>
                允许愚蠢的创造，会鼓励创造。允许不自信，会培育真信心。允许很不开心，抑郁会被定义成一种和快乐并无二致的人类状态。因为我们人类，如果你仔细观察，我们从婴儿状态起就充满了创造力、自信心和无穷的欢乐，而正是社会不断地后天“培养”，让这些千姿百态的优秀特质逐渐消失得无影无踪。
                <br/><br/>
                管住那些关切的目光、激动的双手和热切的心情，比什么都能培育创造、增强信心，并与不开心和平共处。别再强调培养、呵护、帮助这些屁话，简单地允许人按自己的意愿和方式行动，比什么都管用。

            </p>

            <br/>

            <h6 className="App-essay-title">Creativity, Confidence, And Depression</h6>
            <p className="App-essay-article">
                <br/>
                <b>Jul 2023</b>
                <br/><br/>

            </p>
        </div>)
}
