import React from "react";
import {Helmet} from "react-helmet";

export default function Stitches() {
    return (
        <div>
            <Helmet>
                <title>缝针 Stitches</title>
            </Helmet>
            <h6 className="App-essay-title">缝针</h6>
            <p className="App-essay-article">
                <br/>
                <b>二〇二五 三月</b>
                <br/><br/>
                几个月前和爸妈去秦岭里玩儿。他们开车，我坐在后排半躺着。“秦国”的路他们比我熟得多。
                <br/><br/>
                我妈突然问我爸：你当时胆子太大了，那么大的伤口都敢处理？
                <br/><br/>
                我妈说的是多年前，我爸接诊过一个外伤病人。一个年轻小伙子，打架被砍了很多刀，胸口和肩膀的大口子如同刚从解剖室出来，像一头被剖开的猪。
                <br/><br/>
                我爸没说话，径直开始手术，缝了上百针。小伙子是跑江湖的，青龙纹身，但身无分文。我爸没开医药单就让他走了。
                <br/><br/>
                我妈问他：你当时怎么想的？怎么敢接这样的伤口？
                <br/><br/>
                我爸说：我当时也年轻。他也没钱，我不救他，他就死在马路牙上。
                <br/><br/>
                我妈说：那小伙子被缝好，歇了一会儿就站起来，把白背心像毛巾一样，拧了一地血水，穿上背心就走了。年轻人恢复得很快。我跑上楼就吐了。
                <br/><br/>
                我说：伤口这么夸张？<br/>
                我妈说：嗯，还因为你在我肚子里。
                <br/><br/>
                谢了爸妈，你们总有一些故事，比我的故事传奇得多。有些记忆不注意就会溜进脑海里。
                <br/><br/><br/>
                <i style={{color: "darkgray"}}>Written on the coast of the East Pacific Ocean.</i>
                <br/>
                <i style={{color: "darkgray"}}>写于东太平洋沿岸。</i>
            </p>

            <br/>

            <h6 className="App-essay-title">Stitches</h6>
            <p className="App-essay-article">
                <br/>
                <b>Mar 2025</b>
                <br/><br/>

            </p>
        </div>)
}
